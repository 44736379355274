import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomAuthService } from "auth";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  username: string = "";
  password: string = "";
  email: string = "";
  loginError: string = "";
  resetSuccess: boolean = false;
  resetMode: boolean = false; // Determines whether we're in reset password mode

  constructor(private authService: CustomAuthService, private router: Router) {}

  ngOnInit(): void {
    document.body.classList.add("login-background");
  }

  ngOnDestroy(): void {
    document.body.classList.remove("login-background");
  }

  onSubmit(): void {
    this.authService.login(this.username, this.password).subscribe(
      (response) => {
        this.router.navigate(["/home"]);
      },
      (error) => {
        this.loginError = error.error.detail;
        console.error("Login failed", error);
      }
    );
  }

  onResetSubmit(): void {
    this.authService.resetPassword(this.email).subscribe(
      (response) => {
        this.resetSuccess = true;
        this.resetMode = false;
        this.email = "";
      },
      (error) => {
        this.loginError = "Failed to submit reset password request.";
        console.error("Reset password failed", error);
      }
    );
  }

  passwordVisible: boolean = false;
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  switchToReset() {
    this.resetMode = true;
    this.loginError = "";
    this.resetSuccess = false;
  }

  switchToLogin() {
    this.resetMode = false;
    this.loginError = "";
    this.resetSuccess = false;
  }
}
