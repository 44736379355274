<div id="app-login">
  <div id="loginContentBody">
    <div id="mnTitle">
      <img src="/assets/redesign/login/login_mn_title.svg" />
    </div>
    <div id="login-title-container">
      <span id="login-title" *ngIf="!resetMode">LOGIN</span>
      <span id="login-title" *ngIf="resetMode">RESET PASSWORD</span>
    </div>

    <div id="login-form-container">
      <form *ngIf="!resetMode" id="login-form" (ngSubmit)="onSubmit()">
        <div class="input-container">
          <input
            type="text"
            class="login-input"
            id="login-username"
            placeholder="Username"
            [(ngModel)]="username"
            name="username"
          />
        </div>
        <div id="login-password-container" class="input-container">
          <input
            [type]="passwordVisible ? 'text' : 'password'"
            class="login-input"
            id="login-password"
            placeholder="Password"
            [(ngModel)]="password"
            name="password"
          />
          <svg
            *ngIf="passwordVisible"
            (click)="togglePasswordVisibility()"
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="12"
            viewBox="0 0 21 12"
            fill="none"
          >
            <!-- eye open icon here -->
          </svg>
          <svg
            *ngIf="!passwordVisible"
            (click)="togglePasswordVisibility()"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
          >
            <!-- eye closed icon here -->
          </svg>
        </div>
        <div id="forgot-password-container">
          <a id="forgot-password" *ngIf="!resetMode" (click)="switchToReset()"
            >Forgot Password?</a
          >
        </div>
        <div id="login-button-container">
          <button
            id="login-button"
            type="submit"
            [disabled]="!username || !password"
          >
            Login
          </button>
        </div>
      </form>

      <!-- Reset Password Form -->
      <form *ngIf="resetMode" id="reset-form" (ngSubmit)="onResetSubmit()">
        <div class="input-container">
          <input
            type="email"
            class="login-input"
            id="reset-email"
            placeholder="Email"
            [(ngModel)]="email"
            name="email"
            required
          />
        </div>
        <div id="login-button-container">
          <button id="reset-button" type="submit" [disabled]="!email">
            Reset Password
          </button>
        </div>
      </form>

      <!-- Link to switch between Login and Reset Password -->
      <div id="reset-link-container">
        <a *ngIf="resetMode" id="reset-link" (click)="switchToLogin()"
          >Back to Login</a
        >
      </div>
    </div>

    <!-- Success message after password reset -->
    <div id="login-success-message" *ngIf="resetSuccess">
      <p>
        Your request was submitted, please check your email for a new password.
      </p>
    </div>

    <!-- Error message container -->
    <div id="login-error-container" *ngIf="loginError">
      <p id="login-error-message">{{ loginError }}</p>
    </div>
  </div>
</div>
