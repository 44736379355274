import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { FeatureService } from "src/app/services/features.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnChanges {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    public featureService: FeatureService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.featureBaseUrl) {
      this.getFooterIcons();
    }
  }

  @Input() featureBaseUrl: string;

  currentRoute = "";
  footerIcons;
  defaultPath;
  homeIconData = {
    defaultPath: "/home",
    footerIcons: [
      {
        isSelected: false,
        iconSrc: "/assets/redesign/home/home-icon.svg",
        negIconSrc: "/assets/redesign/home/home-neg-icon.svg",
        name: "Home",
      },
    ],
  };

  ngOnInit(): void {
    this.router.events;
    // .pipe(filter((event) => event instanceof NavigationEnd))
    // .subscribe((event: NavigationEnd) => {
    this.updateCurrentRoute(this.router.url);
    this.addIcons();
    //this.cdr.detectChanges();
    // });
    this.getFooterIcons();
  }

  updateCurrentRoute(temp) {
    this.currentRoute = temp;
  }

  getCurrentRoute() {
    return this.currentRoute;
  }

  getHomeIconData() {
    return this.homeIconData;
  }

  getHomeIsSelected() {
    return this.homeIconData.footerIcons[0].isSelected;
  }

  updateHomeIconData(isSelected): void {
    this.homeIconData.footerIcons[0].isSelected = isSelected;
  }

  isCurrentRouteHome() {
    return this.currentRoute === "/home";
  }

  doesCurrentRouteMatch(url) {
    const navigationParam =
      this.route.snapshot.queryParamMap.get("navigationParam");

    return navigationParam === url;
  }

  setIsHomeSelected() {
    if (this.currentRoute === "/home") {
      this.updateHomeIconData(true);
    } else {
      this.updateHomeIconData(false);
    }
  }

  addIcons() {
    this.setIsHomeSelected();

    const footer = document.getElementById("footer");

    function addFooterIcon(fi) {
      const iconDiv = document.createElement("div");
      iconDiv.classList.add("footer-icon");

      // const img = document.createElement("img");
      // img.src = fi.iconSrc;
      // img.alt = fi.name;

      // const text = document.createElement("p");
      // text.textContent = fi.name;

      // iconDiv.appendChild(img);
      // iconDiv.appendChild(text);
      footer.appendChild(iconDiv);

      // iconDiv.addEventListener("click", () => {
      //   document.querySelectorAll(".footer-icon").forEach((icon) => {
      //     icon.classList.remove("selected");
      //   });
      //   iconDiv.classList.add("selected");
      // });
    }
  }

  getFooterIcons() {
    console.log(this.featureBaseUrl);
    this.featureService
      .getFeatureDescriptionByBaseUrl(this.featureBaseUrl)
      .then((rslt) => {
        console.log(rslt);
        if (rslt) {
          this.defaultPath = rslt.defaultPath;
          this.footerIcons = rslt.footerIcons;
        }
      });
  }

  getIconSrc(fi) {
    return `${this.featureBaseUrl}${fi.iconSrc}`;
  }

  getNegIconSrc(fi) {
    return `${this.featureBaseUrl}${fi.negIconSrc}`;
  }

  navigateToFeature(path: string, queryParam): void {
    if (queryParam) {
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate(["/react-test", path.replace(/\//g, "")], {
          queryParams: { navigationParam: queryParam },
        });
      });
    } else {
      this.router.navigate([path]);
    }
  }

  // Add standard "home" icon
  //addFooterIcon(this.homeIconData);

  // Example: Add additional icons
  //addFooterIcon("icon1.svg", "Icon 1", "icon1");
  //addFooterIcon("icon2.svg", "Icon 2", "icon2");

  // Center icons based on the number of icons
  // function centerIcons() {
  //   const icons = document.querySelectorAll(".footer-icon");
  //   if (icons.length === 2) {
  //     footer.style.justifyContent = "center";
  //   } else {
  //     footer.style.justifyContent = "space-around";
  //   }
  // }

  // Initial centering
  //centerIcons();
  // });
}
